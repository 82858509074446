<template>
    <div style="width: 100%">
        <span v-if="existsInDb" class="md-display-3">Edit Map</span>
        <span v-else class="md-display-3">New Map</span>

        <md-card
            class="md-layout-item md-xlarge-size-50 md-large-size-60 md-medium-size-75 md-small-size-100 input-card"
        >
            <md-card-header v-if="!loadingMapInfo && !existsInDb">
                <div class="md-title">Create your own custom map</div>
            </md-card-header>
            <md-card-content>
                <md-content
                    v-if="loadingMapInfo"
                    style="height: 60vh"
                ></md-content>
                <template v-else-if="!existsInDb">
                    <span class="md-body-1" style="color: red"
                        >All information apart from map type can be changed
                        later!</span
                    >
                    <md-steppers
                        :md-active-step.sync="active"
                        md-vertical
                        md-linear
                    >
                        <md-step
                            id="first"
                            md-label="Title and Description"
                            md-description=""
                            :md-editable="false"
                            :md-done.sync="first"
                        >
                            <StepOne
                                v-model="mapInfo"
                                ref="stepOne"
                                :saving="saving"
                            />
                            <md-button
                                @click="completeFirstStep"
                                class="md-raised md-primary continue-btn"
                                >Continue</md-button
                            >
                        </md-step>

                        <md-step
                            id="second"
                            md-label="Map Type"
                            md-description="Hand-picked or Bounded Areas"
                            :md-editable="false"
                            :md-done.sync="second"
                        >
                            <span v-if="mapInfo.isSet" class="md-body-2">
                                With hand-picked you manualy select every
                                panorama that is to be included.<br />
                                This gives you full control of which panoramas
                                can be used during the game.
                            </span>
                            <template v-if="!mapInfo.isSet">
                                <span class="md-body-2">
                                    With bounded areas you select one or more
                                    areas to draw panoramas from.<br />
                                    This option is good if you want to cover
                                    large areas and have more randomization in
                                    what panoramas are picked. <br />
                                </span>
                            </template>

                            <div>
                                <md-radio v-model="mapInfo.isSet" :value="true"
                                    >Hand-picked Locations</md-radio
                                >
                                <md-radio v-model="mapInfo.isSet" :value="false"
                                    >Bounded Areas</md-radio
                                >
                            </div>

                            <span style="color: red"
                                >OBS: Map Type cannot be changed once
                                saved!</span
                            >
                            <br />

                            <md-button
                                @click="completeSecondStep"
                                class="md-raised md-primary continue-btn"
                                >Continue</md-button
                            >
                        </md-step>

                        <md-step
                            id="third"
                            md-label="Add Panoramas"
                            md-description="Select locations or provide MyMaps URL"
                            :md-editable="false"
                            :md-done.sync="third"
                        >
                            <StepThree
                                :isSet="mapInfo.isSet"
                                v-model="mapInfo.url"
                                :loadingPanos="loadingPanos"
                                :hasEnoughPanos="hasEnoughPanos"
                                :nrPanos="nrPanos"
                                @showInfoDialog="showInfoDialog = true"
                                :onlyOutdoorPanosInit="mapInfo.onlyOutdoorPanos"
                                @syncUrl="syncUrl"
                                :saving="saving"
                                :syncing="syncing"
                                :existsInDb="existsInDb"
                                :mapId="mapId"
                            />

                            <md-button
                                @click="completeThirdStep"
                                class="md-raised md-primary continue-btn"
                                >Continue</md-button
                            >
                        </md-step>

                        <md-step
                            id="fourth"
                            class="step4"
                            md-label="Map Center and Score Function"
                            md-description=""
                            :md-editable="false"
                            :md-done.sync="third"
                        >
                            <StepFour
                                v-model="mapInfo"
                                ref="stepFour"
                                :saving="saving"
                            />
                            <md-button
                                @click="completeFourthStep"
                                class="md-raised md-primary continue-btn"
                                >Continue</md-button
                            >
                        </md-step>

                        <md-step
                            id="fifth"
                            md-label="Map image and thumbnail"
                            md-description=""
                            :md-editable="false"
                            :md-done.sync="fourth">
                            <ThumbnailEditor ref="stepFive" :hideUpdate="true" :mapId="Number(mapId)"/>
                        </md-step>
                    </md-steppers>
                </template>
                <template v-else>
                    <StepOne
                        v-model="mapInfo"
                        ref="stepOne"
                        :saving="saving"
                        :existsInDb="existsInDb"
                    />
                    <StepThree
                        :isSet="mapInfo.isSet"
                        v-model="mapInfo.url"
                        :loadingPanos="loadingPanos"
                        :hasEnoughPanos="hasEnoughPanos"
                        :nrPanos="nrPanos"
                        @showInfoDialog="showInfoDialog = true"
                        @syncUrl="syncUrl"
                        @onlyOutdoorPanosChange="setOnlyOutdoorPanos"
                        :onlyOutdoorPanosInit="mapInfo.onlyOutdoorPanos"
                        :saving="saving"
                        :syncing="syncing"
                        :existsInDb="existsInDb"
                        :mapId="mapId"
                    />
                    <StepFour
                        v-model="mapInfo"
                        ref="stepFour"
                        :saving="saving"
                    />
                    <md-divider style="margin: 10px 0"/>
                    <ThumbnailEditor @saved="showThumbnailUpdated" ref="thumbnailEditor" :initPos="mapInfo.thumbnailInfo" :imageIdInit="mapInfo.imgUrl" :mapId="Number(mapId)"/>
                </template>
            </md-card-content>
            <md-card-actions v-if="!loadingMapInfo">
                <template v-if="existsInDb">
                    <md-checkbox
                        v-model="mapInfo.isPublic"
                        :disabled="!canMakePublic || saving"
                        >Make public
                        <md-tooltip md-delay="300" md-direction="top"
                            ><span v-if="mapInfo.isSet && !hasEnoughPanos">
                                Need at least 5 panoramas to make public
                            </span>
                            <span v-else>Add a MyMaps URL to make public</span>
                        </md-tooltip>
                    </md-checkbox>
                    <md-button
                        class="md-raised del-btn"
                        @click="showConfirmDelete = true"
                        :disabled="saving"
                        >Delete</md-button
                    >
                </template>
                <md-button
                    v-if="existsInDb || active === 'fifth'"
                    @click="validateMap"
                    class="md-raised md-primary"
                    ><span v-if="!saving">Save</span>
                    <md-progress-spinner
                        v-else
                        md-mode="indeterminate"
                        class="spinner"
                        :md-diameter="30"
                        :md-stroke="3"
                /></md-button>
            </md-card-actions>
        </md-card>

        <md-snackbar
            md-position="center"
            :md-duration="4000"
            :md-active.sync="showSaved"
        >
            <span>{{ savedMessage }}</span>
        </md-snackbar>

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            md-title="Delete Map?"
            md-content="Delete the map and all data associated with it? (This action cannot be undone!)"
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @md-confirm="deleteMap"
        />
        <md-dialog-confirm
            :md-active.sync="showMinPanReqAlert"
            md-title="Add at least 5 panoramas"
            md-content="Add <strong>at least 5</strong> panoramas in order for the map to be playable."
            md-confirm-text="Add now"
            md-cancel-text="Add later"
            @md-confirm="openPanoEditor"
        />
        <md-dialog id="info-dialog" :md-active.sync="showInfoDialog">
            <md-dialog-title>How to create Bounded Areas map</md-dialog-title>

            <form action="https://www.google.com/maps/d/" target="_blank">
                <md-button
                    type="submit"
                    class="md-raised"
                    style="margin-left: 20px"
                    >Go to Google MyMaps</md-button
                >
            </form>

            <img
                src="/img/step_by_step_how_to_create_map_2.webp"
                v-if="existsInDb"
            />
            <img src="/img/step_by_step_how_to_create_map.webp" v-else />

            <md-dialog-actions>
                <md-button class="md-primary" @click="showInfoDialog = false"
                    >Close</md-button
                >
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";

import StepOne from "../components/MapEditorOverview/StepOne.vue";
import StepThree from "../components/MapEditorOverview/StepThree.vue";
import StepFour from "../components/MapEditorOverview/StepFour.vue";
import ThumbnailEditor from "../components/ThumbnailEditor.vue";

export default {
    name: "MapEditorOverview",
    mixins: [axiosMixin],
    components: {
        StepOne,
        StepThree,
        StepFour,
        ThumbnailEditor,
    },
    data() {
        return {
            active: this.$route.query.n ? "third" : "first",
            first: this.$route.query.n == "true",
            second: this.$route.query.n == "true",
            third: false,
            fourth: false,
            fifth: false,
            

            mapId: null,
            mapInfo: {
                mapName: "New Map",
                mapDesc: "",
                tags: [],
                center: { lat: 0, lng: 0 },
                zoom: 1,
                isSet: true,
                isPublic: false,
                scoreFunction: 0,
                url: "",
                onlyOutdoorPanos: true,
            },
            existsInDb: false,
            nrPanos: 0,

            loadingMapInfo: true,
            loadingPanos: true,

            saving: false,
            savedMessage: "",
            showSaved: false,

            syncing: false,

            showConfirmDelete: false,
            showMinPanReqAlert: false,

            showInfoDialog: false,
        };
    },
    computed: {
        hasEnoughPanos: function () {
            return this.nrPanos > 4;
        },
        canMakePublic: function () {
            // TODO MyMaps must have been synced once to make public
            return !(
                (this.mapInfo.isSet && !this.hasEnoughPanos) ||
                (!this.mapInfo.isSet && !this.mapInfo.url)
            );
        },
    },
    methods: {
        openPanoEditor() {
            this.$router.push({
                name: "EditMapPanos",
                params: { mapId: this.mapId },
            });
        },

        validateMap() {
            // TODO validate step 2
            if (this.$refs.stepOne.validate()) {
                this.save();
            }
        },

        showThumbnailUpdated() {
            this.showSaved = true;
            this.savedMessage = "Thumbnail updated";
        },

        completeFirstStep() {
            if (this.$refs.stepOne.validate()) {
                this.first = true;
                this.active = "second";
            }
        },
        completeSecondStep() {
            // TODO validate step 2
            this.forceSave().then((resp) => {
                this.saving = false;
                if (resp.status == 201) {
                    this.mapId = resp.data;
                    this.second = true;
                    this.active = "third";
                } else {
                    this.showSaved = true;
                    this.savedMessage = "Error saving temporary map";
                }
            });
        },
        setOnlyOutdoorPanos(onlyOutdoorPanos) {
            this.mapInfo.onlyOutdoorPanos = onlyOutdoorPanos;
        },
        completeThirdStep() {
            this.third = true;
            this.active = "fourth";

            // To update leaflet
            window.dispatchEvent(new Event("resize"));
        },
        completeFourthStep() {
            this.fourth = true;
            this.active = "fifth";
            this.$nextTick(() => {
                this.$refs.stepFive.onLoad();
            });
        },
        completeFifthStep() {
            this.fifth = true;
            this.save();
        },

        save() {
            if (
                this.mapInfo.isPublic &&
                this.mapInfo.isSet &&
                this.nrPanos < 5
            ) {
                this.showMinPanReqAlert = true;
                return;
            } else if (
                !this.mapInfo.isSet &&
                (this.mapInfo.isPublic || !!this.mapInfo.url)
            ) {
                // TODO validate url
                if (!this.mapInfo.url) {
                    this.showSaved = true;
                    this.savedMessage = "Could not sync map";
                    return;
                }
            }

            this.forceSave().then((resp) => {
                this.showSaved = true;
                this.saving = false;
                if (resp.status == 200 || resp.status == 201) {
                    if (this.$refs.thumbnailEditor) {
                        this.$refs.thumbnailEditor.save(false);
                    } else if (this.$refs.stepFive) {
                        this.$refs.stepFive.save();
                    }
                    this.existsInDb = true;
                    this.$route.query.n = false;
                    this.savedMessage = "Saved Map!";
                    if (resp.status == 201) {
                        this.$router.push(`${resp.data}`);
                        this.mapId = resp.data;
                    } else if (this.$route.params.mapId !== this.mapId) {
                        this.$router.push(`${this.mapId}`);
                    }
                } else {
                    this.savedMessage = "Error whilst saving!";
                }
            });
        },
        forceSave() {
            this.saving = true;

            const center = this.$refs.stepFour.currentCenter();
            const zoom = this.$refs.stepFour.currentZoom();
            this.mapInfo.center = center;
            this.mapInfo.zoom = zoom;

            this.existsInDb ? this.mapInfo.tags : this.mapInfo.tags;
            let tags = this.mapInfo.tags;
            if (!this.existsInDb) {
                if (this.mapInfo.isSet && !tags.some((v) => v === "Set")) {
                    tags.push("Set");
                } else if (
                    !this.mapInfo.isSet &&
                    !tags.some((v) => v === "Random")
                ) {
                    tags.push("Random");
                }
            }

            const payload = {
                id: this.mapId,
                tags,
                mapName: this.mapInfo.mapName,
                mapDesc: this.mapInfo.mapDesc,
                zoom,
                center:
                    center.lat !== undefined
                        ? center
                        : {
                              lat: center[0],
                              lng: center[1],
                          },
                makePublic: this.mapInfo.isPublic && this.canMakePublic,
                url: this.mapInfo.url,
                scoreFunction: this.mapInfo.scoreFunction,
                isSet: this.mapInfo.isSet,
                onlyOutdoorPanos: this.mapInfo.onlyOutdoorPanos,
            };

            return axiosMixin.methods.makeRequest(
                "post",
                process.env.VUE_APP_SERVER_URL + "map/saveMap",
                null,
                payload
            );
        },
        deleteMap() {
            if (this.mapId == null || this.mapId < 1) {
                return;
            }

            axiosMixin.methods
                .makeRequest(
                    "delete",
                    process.env.VUE_APP_SERVER_URL + "map/deleteMap",
                    {
                        mapId: this.mapId,
                    }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        this.$router.push({
                            name: "Home",
                        });
                    } else {
                        this.showSaved = true;
                        this.savedMessage = "Error whilst deleting!";
                    }
                });
        },
        syncUrl() {
            // TODO validate url
            this.syncing = true;
            axiosMixin.methods
                .makeRequest(
                    "post",
                    process.env.VUE_APP_SERVER_URL + "map/updateMyMap",
                    null,
                    {
                        id: this.mapId,
                        url: this.mapInfo.url,
                    }
                )
                .then((resp) => {
                    this.showSaved = true;
                    this.syncing = false;
                    if (resp.status == 200) {
                        if (this.$refs.thumbnailEditor) {
                            this.$refs.thumbnailEditor.save(false);
                        } else if (this.$refs.stepFive) {
                            this.$refs.stepFive.save();
                        }
                        this.savedMessage = "Synced map";
                    } else if (resp.status == 403) {
                        this.savedMessage =
                            "You are not allowed to sync this map";
                    } else {
                        this.savedMessage = "Error syncing map";
                    }
                });
        },
    },
    created() {
        this.mapId = this.$route.params.mapId;
        if (!this.mapId || this.mapId < 1) {
            this.loadingPanos = false;
            this.loadingMapInfo = false;
            return;
        }

        axiosMixin.methods
            .makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "map/byId/full",
                {
                    mapId: this.mapId,
                }
            )
            .then((resp) => {
                this.loadingMapInfo = false;
                if (resp.status == 200) {
                    this.mapInfo = resp.data;
                    this.existsInDb = !this.$route.query.n || this.$route.query.n === "false";
                } else {
                    this.$router.push({
                        name: "EditMap",
                        params: { mapId: 0 },
                    });
                }
            });

        axiosMixin.methods
            .makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "map/byId/nrLocations",
                {
                    mapId: this.mapId,
                }
            )
            .then((resp) => {
                this.loadingPanos = false;
                if (resp.status == 200) {
                    this.nrPanos = resp.data;
                }
            });
    },
};
</script>

<style scoped>
#info-dialog /deep/ .md-dialog-container {
    overflow: auto;
}

.center-div {
    margin-top: 15px;
}

.input-card {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px;
}

.del-btn {
    float: left;
}
.del-btn div {
    background: red;
}

/deep/ .error-text {
    color: red !important;
    margin-top: 0;
}

#info-btn {
    position: relative;
    bottom: 6px;
}

.continue-btn {
    float: right;
}

.step4 /deep/ .md-stepper-content {
    padding-bottom: 0 !important;
}

.spinner {
    position: relative;
    top: 2px;
}
.spinner /deep/ circle {
    stroke: white !important;
}
</style>