<template>
    <div id="app">
        <md-dialog-confirm
            style="text-align: left"
            :md-active.sync="updateExists"
            md-title="A new update is available"
            md-content="A new version of WhereRU is available, please update to not encounter desynchronization with the server."
            md-confirm-text="Update"
            md-cancel-text="Wait"
            :md-close-on-esc="false"
            :md-click-outside-to-close="false"
            @md-cancel="false"
            @md-confirm="refreshApp"
        />
        <TopBar v-if="$route.name !== 'Login'" />
        <router-view name="ContentAndMenu" />
        <md-app v-if="!hasContentAndMenu">
            <md-app-drawer
                v-if="hasSideMenu && isSmall"
                :md-active.sync="menuOpen"
            >
                <router-view name="SideMenu" />
            </md-app-drawer>
            <md-app-drawer
                v-else-if="hasSideMenu"
                md-persistent="full"
                :md-active.sync="menuOpen"
            >
                <router-view name="SideMenu" />
            </md-app-drawer>
            <md-app-content>
                <router-view />
            </md-app-content>
        </md-app>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TopBar from "./components/TopBar.vue";
import update from "./mixins/update";

export default {
    name: "App",
    components: {
        TopBar,
    },
    mixins: [update],
    computed: {
        ...mapGetters(["menuVisible"]),
        hasSideMenu() {
            return (this.$route.matched[0] && (this.$route.matched[0].components && !!this.$route.matched[0].components.SideMenu)) || this.hasContentAndMenu;
        },
        hasContentAndMenu() {
            return (
                this.$route.matched[0] &&
                this.$route.matched[0].components &&
                !!this.$route.matched[0].components.ContentAndMenu
            );
        },
        isSmall() {
            return window.innerWidth < 1000;
        },
    },
    data() {
        return {
            menuOpen: false,
        };
    },
    props: {
        test: Boolean,
    },
    watch: {
        menuVisible() {
            this.menuOpen = !this.menuOpen;
        },
        hasSideMenu(newVal) {
            this.setHasSideMenu(newVal);
        },
        updateExists(newVal) {
            if (newVal) {
                this.moveUpdateOverlay();
            }
        },
    },
    methods: {
        ...mapMutations(["setHasSideMenu"]),
        moveUpdateOverlay() {
            const el = document.querySelector(".md-overlay");
            el.classList.add("md-overlay-update");
            console.log("HI", el);
        },
    },
    created() {
        this.menuOpen = !this.isSmall;
        this.setHasSideMenu(this.hasSideMenu);
        screen.orientation.unlock();
    },
    mounted() {
        if (this.updateExists) {
            this.moveUpdateOverlay();
        }
    },
};
</script>

<style lang="scss">
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme(
    "default",
    (
        // The primary color of the application
        primary: #44546a,
        // The accent or secondary color
        accent: #51cec4
    )
);

@import "~vue-material/dist/theme/all"; // Apply the theme

html,
body {
    height: 100%;
    margin: 0px;
}

.unselectable-text {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

#app {
    font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    height: 100%;
    display: flex;
    flex-flow: column;
}

#app .md-app-content {
    padding: 0;
    display: flex;
    border: 0;
}
#app .md-drawer {
    border-right: 1px solid #0000;
    border-right-color: rgba(0, 0, 0, 0);
    border-right-color: var(
        --md-theme-default-divider-on-background,
        rgba(0, 0, 0, 0.12)
    );
}

#brand-name {
    position: absolute;
    width: 100%;
    background: rgba(128, 128, 128, 0.5);
    z-index: 2;
}

@media only screen and (max-width: 1200px) {
    .scaling-font-big {
        font-size: 36px;
        line-height: normal;
    }
    .scaling-font-small {
        font-size: 24px;
        line-height: normal;
    }
}

div .leaflet-div-icon {
    border: 0;
    background: #fff0;
}

.overlay-bar {
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 400;
    width: 100%;
    height: 45px;
    background: rgba(0, 0, 0, 0.178);
    display: flex;
    justify-content: center;
    pointer-events: none;
}
.overlay-bar span {
    /*z-index: 500;*/
    color: white;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
}
.overlay-bar .overlay-left {
    justify-content: flex-start;
    flex: 1 0 0;
}
.overlay-bar .overlay-right {
    justify-content: flex-end;
    flex: 1 0 0;
}
.overlay-bar div {
    flex: 3 0 0;
    display: flex;
    margin: 0px 10px;
    justify-content: center;
    align-items: center;
}
.overlay-bar i {
    margin: 0 10px;
    color: white !important;
}

.md-overlay-update {
    z-index: 10 !important;
}
</style>
