<template>
    <md-app>
        <md-app-drawer md-permanent="clipped" id="pano-drawer" :md-active.sync="menuOpen">
            <span class="md-display-1 title">{{ mapName }}</span>

            <div id="top-btn-drawer">
                <md-button
                    class="md-icon-button md-accent md-raised"
                    @click="showIdInput"
                    ><md-icon>download</md-icon>
                    <md-tooltip md-delay="1000">Load from ID</md-tooltip>
                </md-button>
            </div>

            <md-divider class=""></md-divider>

            <md-button
                class="drawer-btn md-accent md-raised"
                @click="savePano()"
                >Add this as a new pano</md-button
            >
            <md-button
                class="drawer-btn md-accent md-raised"
                @click="
                    savePano(true, locations ? locations[selectedIndex] : null)
                "
                >Update selected to this pano</md-button
            >

            <span class="md-caption">!Changes are auto saved!</span>

            <md-list id="pano-list">
                <md-list-item
                    v-for="(location, index) in locations"
                    @click="changePano(index)"
                    :key="`${index} ${location.pano}`"
                    v-bind:class="{ 'selected-pano': selectedIndex === index }"
                >
                    <!-- Input for new description -->
                    <md-field v-if="changingDesc && selectedIndex === index">
                        <label></label>
                        <md-input
                            ref="descInput"
                            v-model="newLocDesc"
                            maxlength="100"
                            v-on:keyup.enter="changeDesc"
                        ></md-input>
                    </md-field>

                    <!-- Display description -->
                    <span v-else class="md-list-item-text">{{
                        `#${locations.length - index} | ` +
                        (location.description
                            ? location.description
                            : `${location.lat} ${location.lng}`)
                    }}</span>

                    <!-- Buttons -->
                    <div v-if="changingDesc && selectedIndex === index">
                        <md-button
                            @click.stop="changingDesc = false"
                            class="md-icon-button md-list-action"
                        >
                            <md-icon>clear</md-icon>
                        </md-button>
                        <md-button
                            @click.stop="changeDesc"
                            class="md-icon-button md-list-action"
                        >
                            <md-icon>done</md-icon>
                        </md-button>
                    </div>
                    <div v-else>
                        <md-button
                            v-if="selectedIndex === index"
                            @click.stop="showChangeDesc(location)"
                            class="md-icon-button md-list-action"
                        >
                            <md-icon>drive_file_rename_outline</md-icon>
                        </md-button>
                        <md-button
                            @click.stop="
                                removePano = { id: location.id, index };
                                removeDialog = true;
                            "
                            class="md-icon-button md-list-action delete-btn"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </div>
                </md-list-item>
            </md-list>

            <md-button
                class="drawer-btn md-primary md-raised"
                @click="toMapEditor"
                >Back</md-button
            >
        </md-app-drawer>

        <!-- Content -->
        <md-app-content>
            <md-content class="container">
                <div id="pano"></div>

                <div class="find-map">
                    <input
                        id="pac-input"
                        class="controls"
                        type="text"
                        placeholder="Find location"
                    />
                    <FloatingMap
                        :canPlaceMarker="false"
                        :panorama="panorama"
                        :settings="settings"
                        :defaultCenterAndZoom="true"
                        :bounds="bounds"
                        @bounds-changed="boundsChanged"
                        :locations="locations"
                        @clickedMarker="changePano"
                    />
                </div>
            </md-content>

            <!-- Confirm remove dialog -->
            <md-dialog-confirm
                :md-active.sync="removeDialog"
                md-title="Remove panorama"
                md-content="Are you sure you want to remove this panorama? This action cannot be undone."
                md-confirm-text="Remove"
                md-cancel-text="Cancel"
                @md-confirm="deletePano(removePano)"
            />

            <!-- Load from id prompt -->
            <md-dialog id="id-dialog" :md-active.sync="idDialog">
                <md-dialog-title>Load panorama from ID or URL</md-dialog-title>
                <md-field>
                    <label>ID/URL...</label>
                    <md-input v-model="idReturn" :disabled="loadingId"></md-input>
                </md-field>
                <md-dialog-actions>
                    <md-button
                        :disabled="loadingId"
                        class="md-accent"
                        @click="idDialog = false"
                        >Cancel</md-button
                    >
                    <md-button
                        :disabled="loadingId"
                        class="md-primary"
                        @click="loadFromId(true)"
                        >Load from ID</md-button
                    >
                    <md-button
                        :disabled="loadingId"
                        class="md-primary md-raised"
                        @click="loadFromId(false)"
                        >Load from Coords</md-button
                    >
                </md-dialog-actions>
            </md-dialog>
        </md-app-content>
    </md-app>
</template>

<script>
import { mapGetters } from "vuex";
import axiosMixin from "../mixins/axiosMixin";
import FloatingMap from "../components/FloatingMap.vue";
import { mapActions } from "vuex";

export default {
    name: "MapEditorPanoramas",
    mixins: [axiosMixin],
    components: {
        FloatingMap,
    },
    data() {
        return {
            locations: [],
            mapId: null,
            mapName: "",
            panorama: null,
            bounds: null,
            searchBox: null,
            selectedIndex: 0,
            settings: {
                zoom: 1,
                center: { lat: 0, lng: 0 },
                clickableIcons: false,
                keyboardShortcuts: false,
                disableDefaultUI: true,
                streetViewControl: true,
            },
            removeDialog: false,
            removePano: {},
            changingDesc: false,
            newLocDesc: null,
            idDialog: false,
            idReturn: "",
            loadingId: false,
            menuOpen: !this.isSmall,
        };
    },

    computed: {
        ...mapGetters(["menuVisible"]),
        isSmall() {
            return window.innerWidth < 1000;
        },
    },

    watch: {
        menuVisible() {
            this.menuOpen = !this.menuOpen;
        },
    },

    methods: {
        ...mapActions(["loadGoogle"]),
        getDescription() {
            return this.panorama && this.panorama.location
                ? this.panorama.location.shortDescription
                : "";
        },
        showChangeDesc(location) {
            this.changingDesc = true;
            this.newLocDesc = location.description
                ? location.description
                : "No description";
            this.$nextTick(function () {
                // Soon as the input renders, focus on it
                this.$refs.descInput[0].$el.focus();
            });
        },
        changeDesc() {
            let loc = this.locations[this.selectedIndex];
            loc.description = this.newLocDesc;
            this.changingDesc = false;
            axiosMixin.methods.makeRequest(
                "put",
                process.env.VUE_APP_SERVER_URL + "map/changePanoDesc",
                {
                    locationId: loc.id,
                    desc: loc.description,
                }
            );
        },

        changePano(index) {
            if (!this.panorama) {
                return;
            }
            index = Number(index);
            if (index !== this.selectedIndex) {
                this.changingDesc = false;
            }
            const location = this.locations[index];
            this.selectedIndex = index;
            this.panorama.setPano(location.pano);
            this.panorama.setPov({
                heading: location.heading,
                pitch: location.pitch,
                zoom: 0,
            });
        },
        deletePano(delObj) {
            const { id, index } = delObj;
            axiosMixin.methods
                .makeRequest(
                    "delete",
                    process.env.VUE_APP_SERVER_URL + "map/deletePanoFromMap",
                    {
                        locationId: id,
                    }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        this.locations = this.locations.filter(
                            (l, i) => i != index
                        );
                        this.selectedIndex = Math.min(
                            this.selectedIndex,
                            this.locations.length - 1
                        );
                    }
                });
        },
        savePano(update, location = {}) {
            const id = location.id;
            let description = location.description;
            if (!description) {
                description = this.getDescription();
            }

            const loc = {
                id: id,
                heading: this.panorama.getPov().heading,
                pitch: this.panorama.getPov().pitch,
                panoId: this.panorama.getPano(),
                position: this.panorama.getPosition(),
                description,
                mapId: this.mapId,
            };
            axiosMixin.methods
                .makeRequest(
                    "post",
                    process.env.VUE_APP_SERVER_URL + "map/savePanoToMap",
                    null,
                    loc
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        const newLoc = {
                            id: resp.data,
                            description,
                            heading: loc.heading,
                            pitch: loc.pitch,
                            pano: loc.panoId,
                            lat: loc.position.lat(),
                            lng: loc.position.lng(),
                        };

                        if (id) {
                            this.locations = this.locations.map((l) =>
                                l.id === resp.data ? newLoc : l
                            );
                        } else {
                            this.locations.unshift(newLoc);
                            this.selectedIndex = 0;
                        }
                    }
                });
        },
        boundsChanged(bounds) {
            this.searchBox.setBounds(bounds);
        },
        toMapEditor() {
            this.$router.push({
                name: "EditMap",
                params: { mapId: this.mapId },
                query: { n: this.$route.query.n },
            });
        },
        showIdInput() {
            // Open dialog for id input
            this.idDialog = true;
        },
        loadFromId(useId) {
            if (!this.idReturn.trim()) return;
            let panoId = useId ? "F:".concat(this.idReturn.trim().split("!1s")[1].split("!2e")[0]).replace('%2F','/') : this.idReturn.trim();

            const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
            const match = panoId.match(regex);

            this.panorama.addListener("status_changed", () => {
                this.panorama.setPov(this.panorama.getPhotographerPov());
                window.google.maps.event.clearListeners(
                    this.panorama,
                    "status_changed"
                );
                if (!match) { // If the panorama was loaded direct from ID we must reload the page or the pano will be black... google
                    console.log(this.panorama.getPano());
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            id: this.panorama.getPano(),
                        },
                    });
                    window.location.reload();
                }
            });

            if (match) {
                this.loadingId = true;
                const lat = match[1];
                const lng = match[2];

                // Make a request to the server to get the actual id
                axiosMixin.methods
                    .makeRequest(
                        "get",
                        process.env.VUE_APP_SERVER_URL + "map/getPanoFromLocation", {
                            lat,
                            lng,
                        }
                ).then((resp) => {
                    this.loadingId = false;
                    if (resp.status == 200) {
                        this.idDialog = false;
                        this.panorama.setPano(resp.data);
                    }
                })
            } else {
                this.panorama.setPano(panoId);
            }

            this.idReturn = "";
        },
    },
    created() {
        this.mapId = this.$route.params.mapId;
        if (!this.mapId) {
            this.$router.push("/"); // Go home
        }

        axiosMixin.methods
            .makeRequest("get", process.env.VUE_APP_SERVER_URL + "map/byId", {
                mapId: this.mapId,
            })
            .then((resp) => {
                this.mapName = resp.data.mapName;
            });

        axiosMixin.methods
            .makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "map/getMapLocations",
                { mapId: this.mapId }
            )
            .then((resp) => {
                let initPano = {
                    pano: "CAoSLEFGMVFpcE5UemhDbE1kTGtkcDU1V3JqOU1LdkpnY1dfeDl4dXdvNEdmTmJX",
                    heading: 34,
                    pitch: 10,
                };
                let panoSet = false;

                if (this.$route.query.id) {
                    initPano.pano = this.$route.query.id;
                    const query = Object.assign({}, this.$route.query);
                    delete query.id;
                    this.$router.replace({ query });
                    panoSet = true;
                }

                if (resp.status == 200) {
                    this.locations = resp.data;
                    if (
                        this.locations &&
                        this.locations.length > 0 &&
                        !panoSet
                    ) {
                        initPano = this.locations[0];
                    }
                }

                this.loadGoogle().then(() => {
                    this.panorama = new window.google.maps.StreetViewPanorama(
                        document.getElementById("pano"),
                        {
                            pano: initPano.pano,
                            pov: {
                                heading: initPano.heading,
                                pitch: initPano.pitch,
                                zoom: 0,
                            },
                            fullscreenControl: false,
                        }
                    );

                    this.panorama.addListener("status_changed", () => {
                        if (panoSet) {
                            this.panorama.setPov(
                                this.panorama.getPhotographerPov()
                            );
                        }
                        window.google.maps.event.clearListeners(
                            this.panorama,
                            "status_changed"
                        );
                    });

                    this.searchBox = new window.google.maps.places.SearchBox(
                        document.getElementById("pac-input")
                    );

                    this.searchBox.addListener("places_changed", () => {
                        const places = this.searchBox.getPlaces();

                        if (places.length == 0) {
                            return;
                        }
                        // For each place, get the icon, name and location.
                        const bounds = new window.google.maps.LatLngBounds();
                        places.forEach((place) => {
                            if (!place.geometry || !place.geometry.location) {
                                return;
                            }

                            if (place.geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                            }
                        });
                        this.bounds = bounds;
                    });
                });
            });
    },
};
</script>

<style scoped>
.find-map {
    position: absolute;
    bottom: 20px;
    right: 10px;
    z-index: 4;
    display: flex;
    flex-flow: column;
}

.floating-map {
    position: unset;
}

@media only screen and (max-width: 600px) {
    .floating-map.large-map {
        position: relative;
        bottom: -20px;
        right: -10px;
        height: calc(100vh - 48px);
        width: 100vw;
    }
}

#pano {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
}

.container {
    height: 100%;
    width: 100%;
}

#pac-input {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
}

.md-app-content {
    padding: 0 !important;
    height: 100%;
}

#confirm-btn {
    position: absolute;
    bottom: 10px;
    right: 50px;
    padding: 10px;
}

.md-drawer {
    min-width: calc(min(400px, 100vw));
}

.drawer-btn {
    width: 95%;
    min-height: 40px;
    margin: 8px;
}

.selected-pano {
    background: rgb(197, 197, 197);
}

.selected-pano .delete-btn div div i {
    color: #5f5f6e !important;
}

.delete-btn div div i {
    color: #a2a2a2 !important;
}

#pano-drawer {
    display: flex;
    flex-flow: column;
}

#pano-list {
    overflow: auto;
}

.title {
    margin-top: 10px;
}

#top-btn-drawer {
    margin: 10px;
    display: flex;
    flex-direction: row-reverse;
}
#id-dialog /deep/ .md-dialog-container {
    width: calc(min(600px, 90vw)) !important;
    padding: 0 10px;
}
</style>